import { graphql, Link } from "gatsby";
import React, { useMemo } from "react";
import Icon, { IconType } from "../../components/Icon";
import RichText from "../../components/RichText";
import BlogSeo from "../../components/Seo/BlogSeo";
import Layout from "../../containers/Layout";
import RelatedArticle from "../../components/Blog/RelatedArticle";
import Section from "../../containers/Section";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import LeftArrow from "../../images/left-arrow.svg";
import SimpleRecommendations from "../../components/Blog/SimpleRecommendations";
import GradientSection from "../../containers/GradientSection";
import { GatsbyImage } from "gatsby-plugin-image";
import TableOfContents from "../../components/TableOfContents";
import Accordion from "../../components/Accordion";
import { useI18next } from "gatsby-plugin-react-i18next";
import { convertBlogSlug } from "../../utils/blog";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../../utils/breadcrumb";
import Button, { ButtonSize, ButtonVariant } from "../../components/Button";
import { Category, CTA_ID, Name, Type } from "../../utils/analytics/constants";
import { nav } from "../../utils/navigation";
import { useSegment } from "../../utils/analytics";

interface BlogArticleProps {
  data: any;
  pageContext: any;
}

const BlogArticle: React.FC<BlogArticleProps> = props => {
  const { pageContext, data } = props;

  const { helpers } = useSegment();

  const publication = data?.publications?.edges.filter((pub: any) => {
    return pub.node.node_locale === pageContext.language;
  })[0].node;

  const localesAvailable = data?.publications?.edges
    ?.map(pub => {
      if (pub?.node?.title !== null && pub?.node?.slug !== undefined) {
        return {
          language: pub.node.node_locale,
          url: convertBlogSlug(pub.node.slug, pub.node.node_locale, false),
          "@type": "BlogPosting"
        };
      }
    })
    .filter(locale => locale);

  const featuredPublications = data?.featured?.edges[0]?.node?.post;
  const image = publication?.bannerImage?.gatsbyImageData
    ? publication?.bannerImage?.gatsbyImageData
    : null;
  //Format date since different format needed for schema
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return new Date(dateString).toLocaleDateString([], options);
  };

  const { t } = useI18next();

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.BlogSingle),
    []
  );

  return (
    <Layout inlineSwitcher localesAvailable={localesAvailable}>
      <BlogSeo
        locales={localesAvailable}
        data={publication}
        title={publication?.seoSettings?.metadataTitle || publication?.title}
        description={
          publication?.seoSettings?.metadataDescription ||
          publication?.bodyPreview
        }
        image={
          publication?.seoSettings?.openGraphImage?.url ||
          publication?.bannerImage?.url
        }
        robots={publication?.seoSettings?.robots}
      />
      <Section>
        <div className="grid grid-cols-8 pb-12">
          <div className="col-span-8 pt-[150px] flex items-center mb-3">
            <CustomBreadcrumb crumbs={updatedCrumbs} />
          </div>
          <div className="col-span-8 lg:col-span-5">
            <div className="pt-4 pb-3">
              <div className="rounded-2xl relative h-[200px] sm:h-[340px] md:h-[440px] w-full overflow-hidden">
                {image && (
                  <GatsbyImage
                    alt={publication?.bannerImage?.alt}
                    image={image}
                    className="absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                  />
                )}
              </div>
            </div>
            <div className="pt-[12px]">
              <div className="flex justify-between">
                {publication?.categories && (
                  <p className="text-green-1 font-semibold">
                    {publication?.categories[0]?.name}
                  </p>
                )}
                {publication?.timeToRead && (
                  <span className="flex">
                    {publication?.timeToRead} min
                    <Icon iconType={IconType.clock} className="ml-3" />
                  </span>
                )}
              </div>
              <h1 className="gray-dark-4 font-semibold text-4xl py-6">
                {publication?.title}
              </h1>
              <p className="font-narrow text-gray-8">
                <span className="font-medium text-indigo-1">
                  {publication?.authors?.map(a => a?.name).join(", ")}
                </span>
                {", "}
                <span className="font-medium text-indigo-1">
                  {formatDate(publication?.publishDate)}
                </span>
              </p>
              <div className="grid grid-cols-6 pt-16 md:pt-[38px] w-full">
                <div className="col-span-6 md:col-span-1 pb-16 lg:pt-0 mx-auto lg:mx-0 lg:pb-0 lg:inline">
                  <div className="flex justify-center items-center space-x-8 md:space-x-0 md:block sticky top-40 md:space-y-6">
                    <a
                      className="block opacity-50 hover:opacity-100"
                      href={`https://www.facebook.com/dialog/share?app_id=512033516992910&display=popup&href=https://perpetua.io/${pageContext?.convertedSlug}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <Icon
                        className="fill-current"
                        iconType={IconType.facebook}
                      />
                    </a>
                    <a
                      className="block opacity-50 hover:opacity-100"
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=https://perpetua.io/${pageContext?.convertedSlug}&;title=${publication?.title}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <Icon
                        className="fill-current"
                        iconType={IconType.linkedin}
                      />
                    </a>
                    <a
                      className="block opacity-50 hover:opacity-100"
                      href={`http://twitter.com/share?text=${publication?.title}%20@PerpetuaLabs&url=https://perpetua.io/${pageContext?.convertedSlug}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <Icon
                        className="fill-current"
                        iconType={IconType.twitter}
                      />
                    </a>
                    <a
                      className="block opacity-50 hover:opacity-100"
                      href={`https://api.whatsapp.com/send?text=https://perpetua.io/${pageContext?.convertedSlug}%20${publication?.title}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <Icon
                        className="fill-current"
                        iconType={IconType.whatsapp}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-5 lg:col-span-5">
                  <div className="w-full">
                    <div>
                      {publication?.showToc === true &&
                        publication?.tocHeadings !== null && (
                          <TableOfContents
                            tocHeadings={publication?.tocHeadings}
                            content={publication?.body?.raw}
                          />
                        )}
                    </div>
                    {publication?.body && <RichText data={publication?.body} />}
                  </div>
                  <div className="accordion">
                    {publication?.childContentfulPublicationFaqsJsonNode
                      ?.title && (
                      <p className="text-4xl font-semibold text-gray-3-dark text-center md:text-left mt-8">
                        {
                          publication?.childContentfulPublicationFaqsJsonNode
                            ?.title
                        }
                        <span className="text-indigo-1"> FAQ</span>
                      </p>
                    )}
                    {publication?.childContentfulPublicationFaqsJsonNode
                      ?.description && (
                      <p className="text-gray-3-dark text-center md:text-left mt-3">
                        {
                          publication?.childContentfulPublicationFaqsJsonNode
                            ?.description
                        }
                      </p>
                    )}
                    {publication?.childContentfulPublicationFaqsJsonNode && (
                      <div
                        id="FAQPage"
                        itemScope
                        itemType="https://schema.org/FAQPage"
                        className="mt-8"
                      >
                        {publication?.childContentfulPublicationFaqsJsonNode?.faqs?.map(
                          (faq, index) => {
                            return (
                              <Accordion
                                key={index}
                                title={faq?.title}
                                content={faq?.description}
                                maxContentHeight={"max-h-[250px]"}
                                type={"faq"}
                              />
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                  {publication?.useSalesCta != false && (
                    <div className="border border-indigo-1 rounded-xl py-14 px-12 mt-12 shadow-lg">
                      {publication?.contactUsCta ? (
                        <p className="text-indigo-1">
                          {publication?.contactUsCta?.contactUsCta}
                        </p>
                      ) : (
                        <div>
                          <p className="text-indigo-1">
                            {t(
                              "To get started or learn more about how Perpetua can help you scale your Amazon Advertising business"
                            )}
                          </p>
                          <div className="flex gap-x-5 mt-4">
                            <Button
                              id={CTA_ID.getStartedBottom.id}
                              variant={ButtonVariant.primary}
                              size={ButtonSize.default}
                              text={t("Get Started")}
                              onClick={() => {
                                helpers
                                  .delayTrack(Name.Cta, {
                                    category: Category.ButtonClick,
                                    type: Type.Button,
                                    text: "Get Started",
                                    page_url: location.href,
                                    destination:
                                      "https://app.perpetua.io/signup",
                                    location: "Sales CTA"
                                  })
                                  .then(() => {
                                    nav(t("https://app.perpetua.io/signup"));
                                  });
                              }}
                            />
                            <Button
                              id={CTA_ID.demoBottom.id}
                              variant={ButtonVariant.secondaryIndigo}
                              size={ButtonSize.default}
                              text={t("Book a Demo")}
                              onClick={() => {
                                helpers
                                  .delayTrack(Name.Cta, {
                                    category: Category.ButtonClick,
                                    type: Type.Button,
                                    text: "Book a Demo",
                                    page_url: location.href,
                                    destination: "/demo/",
                                    location: "Sales CTA"
                                  })
                                  .then(() => {
                                    nav(t("/demo/"));
                                  });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:col-span-2 lg:col-start-7">
            <p className="text-4xl gray-dark-4 font-semibold pb-6">
              {t("Related Articles")}
            </p>
            {featuredPublications?.map(node => {
              const publication = node?.node || node;
              return (
                <RelatedArticle
                  key={publication.id}
                  publication={publication}
                />
              );
            })}
          </div>
        </div>
        <div className="mx-[-24px] sm:mx-[0px] pt-18 pb-0 sm:pb-12 sm:pt-12">
          <EmbeddedCta
            fullWidth
            buttonText={t("Sign Up")}
            title={t("Insights to help grow profit")}
            textFieldPlaceholder={t("Email address")}
            successText={t(
              "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
            )}
          />
        </div>
      </Section>
      <GradientSection className="px-0 py-12 bg-gradient-to-b from-gray-6 to-indigo-3-light">
        <SimpleRecommendations
          publications={featuredPublications?.slice(0, 3)}
          title={t("Top Stories")}
        />
      </GradientSection>
    </Layout>
  );
};

export default BlogArticle;

export const query = graphql`
  query PublicationBySlug2($contenfulId: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    publications: allContentfulPublication(
      filter: { contentful_id: { eq: $contenfulId } }
    ) {
      edges {
        node {
          id
          title
          slug
          contentful_id
          node_locale
          authors {
            name
          }
          publishDate(formatString: "YYYY-MM-DD")
          createdAt(formatString: "YYYY-MM-DD")
          updatedAt(formatString: "YYYY-MM-DD")
          timeToRead
          seoSettings {
            metadataDescription
            metadataTitle
            openGraphImage {
              url
            }
            robots
          }
          bannerImage {
            gatsbyImageData(width: 1800, layout: FULL_WIDTH)
          }
          bodyPreview
          showToc
          tocHeadings
          body {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData
                file {
                  url
                  fileName
                }
              }
              ... on ContentfulPerpetuaEmbeddedContent {
                contentful_id
                script {
                  script
                }
              }
              ... on ContentfulBlogQuote {
                contentful_id
                blogQuoteModel
                quote {
                  quote
                }
                personNameAndTitle
                personImage {
                  gatsbyImageData
                  url
                }
              }
              ... on ContentfulBlogCta {
                contentful_id
                title
                size
                ctaDescription {
                  raw
                }
                image {
                  gatsbyImageData(height: 301, width: 496)
                  url
                }
                buttonText
                buttonLink
              }
            }
          }
        }
      }
    }
    featured: allContentfulFeaturedBlogPosts(
      filter: {
        contentful_id: { eq: "2kwd54qSBW0e5vvqJ45JTG" }
        node_locale: { eq: $language }
      }
      sort: { order: DESC, fields: post___publishDate }
      limit: 4
    ) {
      edges {
        node {
          post {
            id
            slug
            title
            node_locale
            bodyPreview
            bannerImage {
              gatsbyImageData(width: 1800, layout: FULL_WIDTH)
              url
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
